import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-table", {
    ref: "multipleTable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData
    },
    on: {
      "selection-change": _vm.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "35"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "25",
      "show-overflow-tooltip": true,
      "class-name": "domain-cell",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.domain == _vm.zoneFqName ? _c("el-tooltip", {
          attrs: {
            content: _vm.$t("domain.table-tip-key"),
            placement: "top",
            "open-delay": 500
          }
        }, [_c("i", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: scope.row.domain == _vm.zoneFqName,
            expression: "scope.row.domain==zoneFqName"
          }],
          staticClass: "icon-key1 primary-domain"
        })]) : _c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: _vm.formatStatus(scope.row.status),
            placement: "top",
            "open-delay": 500
          }
        }, [scope.row.status == 1 ? _c("span", {
          staticClass: "icon-dot_single domain-status-pause"
        }) : _c("span", {
          staticClass: "icon-dot_single domain-status-normal"
        })])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("domain.table-title-domain"),
      "class-name": "domain-cell"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("a", {
          staticClass: "domain-name",
          on: {
            click: function click($event) {
              return _vm.updateDomainRR(scope.row);
            }
          }
        }, [_vm._v(_vm._s(_vm._f("formatDomain")(scope.row.domain)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("common.table.title-last-opt-time"),
      width: "240"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("i", {
          staticClass: "el-icon-time"
        }), _vm._v(" " + _vm._s(_vm._f("formatDate")(scope.row.update_time)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("common.table.title-opt"),
      width: "500",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", {
          staticClass: "domain-list-opr"
        }, [_c("el-tooltip", {
          attrs: {
            content: _vm.domainStatusBtnTip(scope.row),
            placement: "top",
            "open-delay": 500
          }
        }, [_c("div", {
          staticStyle: {
            display: "inline-block"
          }
        }, [_c("el-button", {
          directives: [{
            name: "sec",
            rawName: "v-sec",
            value: ["/domains/updatedomainstatus@PUT"],
            expression: "['/domains/updatedomainstatus@PUT']"
          }],
          attrs: {
            type: "text",
            icon: scope.row.status == 0 ? "icon-pause1" : "icon-play",
            disabled: _vm.zoneStatus == 2 || scope.row.domain == _vm.zoneFqName
          },
          on: {
            click: function click($event) {
              return _vm.handleMenuCommand({
                flag: "status",
                row: scope.row
              });
            }
          }
        })], 1)]), _c("el-tooltip", {
          attrs: {
            content: _vm.$t("common.button.clone"),
            placement: "top",
            "open-delay": 500
          }
        }, [_c("div", {
          staticStyle: {
            display: "inline-block"
          }
        }, [_c("el-button", {
          directives: [{
            name: "sec",
            rawName: "v-sec",
            value: ["/domains/:domain@POST"],
            expression: "['/domains/:domain@POST']"
          }],
          attrs: {
            type: "text",
            icon: "el-icon-copy-document",
            disabled: scope.row.domain == _vm.zoneFqName
          },
          on: {
            click: function click($event) {
              return _vm.cloneRow(scope.row);
            }
          }
        })], 1)]), _c("el-tooltip", {
          attrs: {
            content: scope.row.remark ? scope.row.remark : _vm.$t("domain.add-button-remark"),
            placement: "top",
            "open-delay": 500
          }
        }, [_c("div", {
          staticStyle: {
            display: "inline-block"
          }
        }, [_c("el-button", {
          directives: [{
            name: "sec",
            rawName: "v-sec",
            value: ["/domainindex/remark/:domain@PUT"],
            expression: "['/domainindex/remark/:domain@PUT']"
          }],
          staticStyle: {
            "font-size": "20px"
          },
          attrs: {
            type: "text",
            icon: scope.row.remark ? "icon-file-text2" : "icon-file-empty"
          },
          on: {
            click: function click($event) {
              return _vm.addRemark(scope.row);
            }
          }
        })], 1)]), _c("el-tooltip", {
          attrs: {
            content: _vm.$t("common.msg.show-queries"),
            placement: "top",
            "open-delay": 500
          }
        }, [_c("div", {
          staticStyle: {
            display: "inline-block"
          }
        }, [_c("el-button", {
          attrs: {
            type: "text",
            icon: "el-icon-s-data"
          },
          on: {
            click: function click($event) {
              return _vm.openQueryDialog(scope.row);
            }
          }
        })], 1)]), _c("el-tooltip", {
          attrs: {
            content: _vm.$t("domain.table-icon-hc") + ": " + _vm.getStatus(scope.row.hc_status),
            placement: "top",
            "open-delay": 500
          }
        }, [_c("div", {
          staticStyle: {
            display: "inline-block"
          }
        }, [scope.row.hc_status == 1 ? _c("img", {
          staticStyle: {
            width: "22px",
            "vertical-align": "middle",
            margin: "8px 13px"
          },
          attrs: {
            src: require("@/assets/systemtask.png"),
            alt: _vm.$t("domain.table-icon-hc") + ": " + _vm.getStatus(scope.row.hc_status),
            title: _vm.$t("domain.table-icon-hc") + ": " + _vm.getStatus(scope.row.hc_status)
          }
        }) : _c("el-button", {
          class: scope.row.hc_status == 0 ? "hc-monitor" : "no-data",
          attrs: {
            type: "text",
            icon: "icon-hc_monitor",
            disabled: scope.row.hc_status == -1
          },
          on: {
            click: function click($event) {
              return _vm.showHcTask(scope.row);
            }
          }
        })], 1)]), _c("el-tooltip", {
          attrs: {
            content: _vm.$t("common.button.delete"),
            placement: "top",
            "open-delay": 500
          }
        }, [_c("div", {
          staticStyle: {
            display: "inline-block"
          }
        }, [_c("el-button", {
          directives: [{
            name: "sec",
            rawName: "v-sec",
            value: ["/domains/:domain@DELETE"],
            expression: "['/domains/:domain@DELETE']"
          }],
          attrs: {
            type: "text",
            icon: "el-icon-delete",
            disabled: scope.row.domain == _vm.zoneFqName
          },
          on: {
            click: function click($event) {
              return _vm.deleteRow(scope.row);
            }
          }
        })], 1)]), _c("el-dropdown", {
          on: {
            command: _vm.handleMenuCommand
          }
        }, [_c("span", {
          staticClass: "el-dropdown-link"
        }, [_c("i", {
          staticClass: "el-icon-more btn-record-more"
        })]), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_c("el-tooltip", {
          attrs: {
            content: _vm.domainHcStatusBtnTip(scope.row),
            placement: "top",
            "open-delay": 500,
            disabled: scope.row.status == 0 && _vm.zoneStatus != 2
          }
        }, [_c("div", [scope.row.hc_status == 0 || scope.row.hc_status == -1 ? _c("el-dropdown-item", {
          attrs: {
            command: _vm.wrapCommandData("hc", scope.row),
            disabled: scope.row.hc_status == -1 || _vm.zoneStatus == 2 || scope.row.status == 1
          }
        }, [_vm._v(_vm._s(_vm.$t("domain.table-menu-hc-disable")))]) : _c("el-dropdown-item", {
          attrs: {
            command: _vm.wrapCommandData("hc", scope.row),
            disabled: _vm.zoneStatus == 2 || scope.row.status == 1
          }
        }, [_vm._v(_vm._s(_vm.$t("domain.table-menu-hc-enable")))])], 1)])], 1)], 1)], 1)];
      }
    }])
  })], 1), _c("el-pagination", {
    style: {
      float: "right",
      padding: "20px"
    },
    attrs: {
      "current-page": _vm.page,
      "page-size": _vm.pageSize,
      "page-sizes": [10, 30, 50, 100],
      total: _vm.total,
      layout: "slot, sizes, prev, pager, next, jumper"
    },
    on: {
      "current-change": _vm.handleCurrentChange,
      "size-change": _vm.handleSizeChange
    }
  }, [_c("span", {
    staticClass: "record-total"
  }, [_vm._v(" 总计 "), _c("b", [_vm._v(_vm._s(_vm.total))]), _vm._v(" 条域名 ")])]), _c("el-dialog", {
    attrs: {
      "before-close": _vm.closeDialog,
      visible: _vm.dialogFormVisible,
      title: _vm.type == "create" ? _vm.$t("domain.add-dlg-title") : _vm.$t("domain.modify-dlg-title"),
      width: "75%",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "formData",
    attrs: {
      model: _vm.formData,
      "label-position": "right",
      "label-width": "20px",
      size: "mini"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "padding-left": "45px"
    },
    attrs: {
      prop: "name",
      rules: [{
        required: !_vm.isPrimaryDomain,
        message: _vm.$t("domain.checkdomain-error-empty"),
        trigger: "blur"
      }, {
        type: "string",
        message: _vm.$t("domain.checkdomain-error-invalid"),
        trigger: "blur",
        validator: _vm.validateDomain
      }]
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 17
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: _vm.$t("domain.dlg-input-domain"),
      disabled: _vm.type == "update"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "formData.name"
    }
  }, [_c("template", {
    slot: "append"
  }, [_vm._v(_vm._s(_vm.zoneName))])], 2)], 1)], 1)], 1), _c("el-form-item", {
    staticClass: "recorder-item"
  }, [_c("region", {
    attrs: {
      isDefault: true,
      regionRow: _vm.record.default,
      zone: _vm.qZone,
      isPrimaryDomain: _vm.isPrimaryDomain,
      recorderType: _vm.defaultRecorderType,
      zoneStatus: _vm.zoneStatus,
      isPtrZone: _vm.isPtrZone
    }
  })], 1), _vm._l(_vm.record.region, function (row, index) {
    return _c("el-form-item", {
      key: index,
      staticClass: "recorder-item"
    }, [_c("region", {
      ref: "smart",
      refInFor: true,
      attrs: {
        regionRow: row,
        idx: index,
        zone: _vm.qZone,
        recorderType: _vm.smartRecorderType,
        zoneStatus: _vm.zoneStatus
      },
      on: {
        delRegion: _vm.delRegion
      }
    })], 1);
  }), !_vm.isPrimaryDomain && !_vm.isPtrZone ? _c("el-form-item", {
    staticClass: "recorder-item"
  }, [_c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-plus",
      round: ""
    },
    on: {
      click: _vm.addRegion
    }
  }, [_vm._v(_vm._s(_vm.$t("domain.add-btn")))])], 1) : _vm._e()], 2), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("common.button.cancel")))]), _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading.fullscreen.lock",
      value: _vm.fullscreenLoading,
      expression: "fullscreenLoading",
      modifiers: {
        fullscreen: true,
        lock: true
      }
    }, {
      name: "sec",
      rawName: "v-sec",
      value: ["/domains/:domain@POST", "/domains/full-sized/:domain@PUT"],
      expression: "['/domains/:domain@POST', '/domains/full-sized/:domain@PUT']"
    }],
    attrs: {
      type: "primary",
      disabled: _vm.isDisabledSaveBtn
    },
    on: {
      click: function click($event) {
        return _vm.enterDialog("formData");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.button.confirm")))])], 1)], 1), _c("el-dialog", {
    attrs: {
      "before-close": _vm.closeCloneDialog,
      visible: _vm.cloneDialogFormVisible,
      title: _vm.$t("domain.clone-dlg-title"),
      width: "50%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.cloneDialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "cloneFormData",
    attrs: {
      model: _vm.cloneFormData,
      "label-position": "right",
      "label-width": "80px",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "name",
      rules: [{
        required: true,
        message: _vm.$t("domain.checkdomain-error-empty"),
        trigger: "blur"
      }, {
        type: "string",
        message: _vm.$t("domain.checkdomain-error-invalid"),
        trigger: "blur",
        validator: _vm.validateDomain
      }]
    }
  }, [_c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: _vm.$t("domain.dlg-input-domain")
    },
    model: {
      value: _vm.cloneFormData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.cloneFormData, "name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "cloneFormData.name"
    }
  }, [_c("template", {
    slot: "append"
  }, [_vm._v(_vm._s(_vm.zoneName))])], 2)], 1)], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeCloneDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("common.button.cancel")))]), _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading.fullscreen.lock",
      value: _vm.fullscreenLoading,
      expression: "fullscreenLoading",
      modifiers: {
        fullscreen: true,
        lock: true
      }
    }],
    attrs: {
      type: "primary",
      disabled: _vm.isDisabledSaveBtn
    },
    on: {
      click: function click($event) {
        return _vm.saveCloneDialog("cloneFormData");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.button.confirm")))])], 1)], 1), _c("el-dialog", {
    attrs: {
      "before-close": _vm.closeAddRemarkDialog,
      visible: _vm.addRemarkDialogFormVisible,
      title: _vm.$t("domain.dlg-record-remark"),
      width: "520px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addRemarkDialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "remarkFormData",
    attrs: {
      model: _vm.remarkFormData,
      "label-position": "right"
    }
  }, [_c("el-form-item", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: _vm.$t("domain.input-remark"),
      maxlength: "100",
      "show-word-limit": "",
      rows: "6"
    },
    model: {
      value: _vm.remarkFormData.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.remarkFormData, "remark", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "remarkFormData.remark"
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeAddRemarkDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("common.button.cancel")))]), _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading.fullscreen.lock",
      value: _vm.fullscreenLoading,
      expression: "fullscreenLoading",
      modifiers: {
        fullscreen: true,
        lock: true
      }
    }],
    attrs: {
      type: "primary",
      disabled: _vm.isDisabledSaveBtn
    },
    on: {
      click: function click($event) {
        return _vm.saveAddRemarkDialog("remarkFormData");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.button.confirm")))])], 1)], 1), _c("el-dialog", {
    attrs: {
      "before-close": _vm.closeQueryDialog,
      visible: _vm.queryVisible,
      title: "24HRS Queries",
      width: "60%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.queryVisible = $event;
      }
    }
  }, [_c("HCharts", {
    attrs: {
      param: _vm.param
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };